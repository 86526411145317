import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router from './router'
import store from './store'
import '@/style/common/common.scss';
import "./permission";
import 'element-ui/lib/theme-chalk/index.css';
// api
import { post, get, put, del , File } from "@/api/http";
import VueClipboard from 'vue-clipboard2';


// 组件
import TitleComponents from "@/components/TitleComponents/index.vue";

// api
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$put = put;
Vue.prototype.$del = del;
Vue.prototype.$file = File;
Vue.use(ElementUI);
Vue.use(VueClipboard);
Vue.component("TitleComponents",TitleComponents)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
