import router from "@/router";
import store from "@/store";

const whiteList = ["404"];

router.beforeEach(async (to, from, next) => {
    let token = store.state.token;
    setTitle(to.meta.title);
    if (token) {
        if (to.name === 'login') {
            login_out();
            next({ name: 'login' })
        }else {
            if (store.state.menu.length === 0 || router.getRoutes().length <= 2) {
                // let userInfo = await store.dispatch("getUserInfo");
                let accessRoutes = await store.dispatch("GenerateRoutes");
                // store.commit("SET_ROLES",["admin"])
                // router.addRoutes(accessRoutes)
                // console.log(router.getRoutes())
                // console.log(to)
                next({ ...to, replace: true })
            } else {
                next();
            }
        }
    } else if (to.name === "login") {
        login_out();
        return next();
    }else if (whiteList.includes(to.path)) {
        return next();
    }else {
        login_out();
        return next({ name: 'login' })
    }

})

function setTitle(title) {
    if (title) {
        document.title = title
    } else {
        document.title = "后台管理"
    }
}

function login_out() {
    store.commit("SET_TOKEN","");
    store.commit("SET_ROLES",[]);
    store.commit("SET_MENU",[]);
    // store.commit("SET_PERMISSIONS","");
    // store.commit("SET_ROLES","");
}
