const dynamicRoutes = [
    {
        path: "/userDetails/:id",
        name: "userDetails",
        component: () => import("@/views/modules/user/userDetails"),
        meta: {
            title: "用户管理 - 详情",
            activeMenu: "/userList"
        }
    },
    {
        path: "/addressList/:id",
        name: "addressList",
        component: () => import("@/views/modules/user/addressList"),
        meta: {
            title: "地址管理",
            activeMenu: "/userList"
        }
    },
    {
        path: "/bannerAdd",
        name: "bannerAdd",
        component: () => import("@/views/modules/platformSet/bannerAdd"),
        meta: {
            title: "Banner操作",
            activeMenu: "/bannerSet"
        }
    },
    {
        path: "/diamondAreaAdd",
        name: "diamondAreaAdd",
        component: () => import("@/views/modules/platformSet/diamondAreaAdd"),
        meta: {
            title: "金刚区操作",
            activeMenu: "/diamondAreaSet"
        }
    },
    {
        path: "/homeStaticDiagramAdd",
        name: "homeStaticDiagramAdd",
        component: () => import("@/views/modules/platformSet/homeStaticDiagramAdd"),
        meta: {
            title: "首页静态页操作",
            activeMenu: "/homeStaticDiagramSet"
        }
    },
    {
        path: "/productAdd",
        name: "productAdd",
        component: () => import("@/views/modules/productManage/productAdd"),
        meta: {
            title: "产品列表操作",
            activeMenu: "/productList"
        }
    },
    {
        path: "/productRecommendations/:id",
        name: "productRecommendations",
        component: () => import("@/views/modules/productManage/productRecommendations"),
        meta: {
            title: "推荐产品",
            activeMenu: "/productList"
        }
    },
    {
        path: "/orderDetails/:id",
        name: "orderDetails",
        component: () => import("@/views/modules/orderManage/orderDetails"),
        meta: {
            title: "订单详情",
            activeMenu: "/orderList"
        }
    },
]

export default dynamicRoutes;