<template>
  <div class="container">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "TitleComponents",
  props: {
    text: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {

    }
  },
  computed: {
    title() {
      let str = this.$route.meta["title"];
      if (this.text) {
        str = this.text;
      }
      return str;
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.container{
  position: relative;
  font-size: 20px;
  font-weight: bold;
  padding-left: 14px;
  margin-bottom: 15px;
  &::after{
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 80%;
    background: #FF9900;
  }
}
</style>