import axios from 'axios';
// import Qs from 'qs'
import router from '@/router';
import store from "@/store";
import { Message, Notification } from 'element-ui';


// axios.defaults.baseURL = '/api'
axios.defaults.baseURL = process.env["VUE_APP_API_SERVER"]
axios.defaults.timeout = 60000

axios.interceptors.request.use(
    config => {
        if (!config.headers['Content-Type']) {
            config.headers = {
                "Content-Type": "application/json;charset=utf-8;"
            }
        }

        const token = store.state.token;
        if (token) {
          config.headers.token = token;
        }

        return config
    },
    error => {
        // 处理请求错误
        return Promise.reject(error)
    }
)
axios.interceptors.response.use(
    res => {
        // console.log(res.data);
        // console.log(res);
        if(res.data.code) {

            if(res.headers["token"]) {
                store.commit("setToken",res.headers.authorization)
            }

            return res.data

        }else if (res.config.responseType === "blob") {
            if (res.data.type.includes('application/json')) {
                let reader = new FileReader();
                reader.readAsText(res.data, 'utf-8');
                reader.onload = function () {
                    Notification.error({
                        title: "操作失败",
                        message: JSON.parse(reader.result).msg
                    })
                }
                return Promise.reject(res)
            }else {
                return res;
            }
        }else {
            Notification.error({
                title: "操作失败",
                message: res.data.msg
            })
            if (res.data.data === 101 || res.data.data === 102) {
                router.replace({
                    name: "login"
                })
            }
            return Promise.reject(res)
        }

    },
    error => {
        if (error.code === 'ECONNABORTED') {
            console.log('连接超市，网络不加')
        } else if (error.response.status === 400) {
            // Message.error(error.response.data.msg);
            Notification.error({
                title: error.response.data.msg
            })
        } else if (error.response.status === 401) {
            router.replace({
                path: "/"
            })
            Notification.error({
                title: error.response.data.msg
            })
        }  else if (error.response.status === 403) {
            router.replace({
                path: "/"
            })
            Notification.error({
                title: error.response.data.msg
            })
        } else if (error.response.status === 500) {
            if(error.response.data.message) {
                Notification.error({
                    title: error.response.data.msg
                })
            }else {
                Notification.error({
                    title: "系统异常"
                })
            }
        }
        return Promise.reject(error);
    }
)

export function get(url, params, falg) {
    return new Promise((resolve, reject) => {
        axios.get(falg ? url + "/" + params : url, falg ? {} : {
            params: params,
        })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function File(url, params) {
    return new Promise((resolve, reject) => {
        axios({
            url,
            method: "post",
            data: params,
            responseType: 'blob'
        })
            .then(res => {
                console.log(res)
                const fileName = res.headers["content-disposition"].split(";")[1].split("=")[1];
                let url = window.URL.createObjectURL(res.data);
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', decodeURIComponent(fileName));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}


export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then(res => {
                resolve(res)
            }, err => {
                reject(err)
            })
    })
}

export function put(url, data) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(res => {
                resolve(res)
            }, err => {
                reject(err)
            })
    })
}

export function del(url, params, falg) {
    return new Promise((resolve, reject) => {
        axios.delete(falg ? url + "/" + params : url, falg ? {} : {
            params: params
        })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
