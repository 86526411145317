import LoginView from "@/views/Login/index.vue";

const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginView
    },
    {
        path: "/index",
        name: "index",
        redirect: "/default",
        component: () => import("@/views/mainIndex"),
        /*children: [
            {
                path: "/userList",
                name: "userList",
                component: () => import("@/views/modules/user/userList"),
                meta: {
                    title: "用户管理"
                }
            },
            {
                path: "/bannerSet",
                name: "bannerSet",
                component: () => import("@/views/modules/platformSet/bannerSet"),
                meta: {
                    title: "Banner设置"
                }
            },
            {
                path: "/diamondAreaSet",
                name: "diamondAreaSet",
                component: () => import("@/views/modules/platformSet/diamondAreaSet"),
                meta: {
                    title: "金刚区设置"
                }
            },
            {
                path: "/homeStaticDiagramSet",
                name: "homeStaticDiagramSet",
                component: () => import("@/views/modules/platformSet/homeStaticDiagramSet"),
                meta: {
                    title: "首页静态页"
                }
            },
            {
                path: "/automaticReceiptSet",
                name: "automaticReceiptSet",
                component: () => import("@/views/modules/platformSet/automaticReceiptSet"),
                meta: {
                    title: "自动收货设置"
                }
            },
            {
                path: "/classifiedManage",
                name: "classifiedManage",
                component: () => import("@/views/modules/productManage/classifiedManage"),
                meta: {
                    title: "分类管理"
                }
            },
            {
                path: "/productList",
                name: "productList",
                component: () => import("@/views/modules/productManage/productList"),
                meta: {
                    title: "产品列表"
                }
            },
            {
                path: "/orderList",
                name: "orderList",
                component: () => import("@/views/modules/orderManage/orderList"),
                meta: {
                    title: "订单列表"
                }
            },

            {
                path: "/transactionData",
                name: "transactionData",
                component: () => import("@/views/modules/financialManage/transactionData"),
                meta: {
                    title: "交易数据"
                }
            },

            {
                path: "/role",
                name: "role",
                component: () => import("@/views/modules/system/role"),
                meta: {
                    title: "角色设置"
                }
            },
            {
                path: "/user",
                name: "user",
                component: () => import("@/views/modules/system/user"),
                meta: {
                    title: "用户设置"
                }
            },
        ]*/
    },
]

export default routes;