<template>
  <div class="container" >

    <div class="box">

      <div class="title">
        <h2>美凌后台管理</h2>
      </div>

      <el-form class="form" :model="form" ref="form" :rules="rules" label-width="60px" :hide-required-asterisk="true" @submit.native.prevent >
        <el-form-item label="用户名" prop="name" >
          <el-input id="username" clearable v-model="form.name" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass" >
          <el-input id="password" clearable type="password" show-password v-model="form.pass" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code" >
          <div class="code">
            <el-input clearable v-model="form.code" placeholder="请输入验证码"></el-input>
            <el-image :src="code_url" @click="codeClickFn" ></el-image>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="but">
            <el-button id="buttons" class="button" type="primary" native-type="submit" @click="onSubmit(form)">登录</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
export default {
  name: "loginView",
  data() {
    return{
      code_url: "",
      form: {
        name: "",
        pass: "",
        code: "",
        uKey:  Math.floor(Math.random() * 100) + 1,
      },
      rules: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        pass: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          // { min: 6, max: 20, message: '密码长度在 6 到 20 位', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ]
      }
    }
  },
  created() {
    this.code_url = `${process.env.VUE_APP_API_SERVER}login/get_verify?data=${new Date()}&uKey=${this.form.uKey}`;
  },
  mounted() {

  },
  methods: {
    codeClickFn() {
      this.code_url = `${process.env.VUE_APP_API_SERVER}login/get_verify?data=${new Date()}&uKey=${this.form.uKey}`;
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid) {

          this.$post("login/login",this.form)
              .then(res => {
                if (!res) return false;
                console.log(res)
                let data = res.data;
                this.$store.commit("SET_TOKEN",data.token);
                this.$store.commit("SET_INFO",{
                  nickName: this.form.name
                })
                this.$router.replace({
                  path: "/default"
                })
              })
              .catch(() => {
                this.codeClickFn();
              })

        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background: url("@/assets/images/loginBackground.png") no-repeat;
  background-size: cover;*/
  background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);

  .box{
    background: rgba(255,255,255,.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 30px 25px 15px;
    border-radius: 10px;
    .title{
      margin-bottom: 30px;
      margin-left: 30px;
    }
    .form{

      ::v-deep .el-form-item__label{
        color: #FFFFFF;
      }

      .but{
        display: flex;
        justify-content: center;
        align-items: center;
        .button{
          width: 80%;
        }
      }

    }
  }

}
.code{
  display: flex;
  align-items: center;
  .el-image{
    width: 120px;
    height: 50px;
    margin-left: 20px;
    cursor: pointer;
  }
}
</style>