import router from '@/router'
import dynamicRoutes from "@/router/dynamicRoutes"

export default function getMenu(e) {
	return new Promise(resolve => {
		if (e && e.length !== 0) {
			let menuList = getMenuList(e);
			let index = {
				path: '/index',
				name: "index",
				component: () => import('@/views/mainIndex'),
				meta: {
					title: '首页',
					keep: false,
				},
				/*redirect: {
					name: menuList[0].menuUrl
				},*/
			}

			let dynamicRoutesArr = (dynamicRoutes || []).filter(item => {
					let flag = menuList.some(item_ => Boolean(`/${item_.menuUrl}` === item.meta.activeMenu));
					if (flag) return item;
				}),
				children = (menuList || []).map((item,index) => {
				return {
					path: '/' + item.menuUrl,
					name: item.menuUrl,
					alias: index === 0 ? ["/default"] : [],
					component: () => import(`@/views/${item.components}`),
					meta: {
						title: item.menuName,
					},
				}
			}).concat(dynamicRoutesArr)

			let Four = {
				path: '*',
				component: () => import('@/views/error/FourError'),
				meta: {
					title: '404',
				},
			}

			index['children'] = children

			router.addRoute(index)
			router.addRoute(Four)

			resolve(index);
		} else {
			router.push({
				path: '/',
			})
		}
	})
}

function getMenuList(e) {
	let arr = (e || []).filter(item => item.child && item.child.length > 0),
		menuList = (arr || []).map((item) => {
			if (item.child && item.child.length > 0) {
				let arrChildren = getMenuList(item.child);
				// Reflect.deleteProperty(item,'children')
				return [
					...arrChildren,
					...item.child,
				]
			}
			return item.child
		}).flat(Infinity)
	return menuList;
}
