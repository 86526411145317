import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import getMenu from "@/utils/getMenu";
import { post } from "@/api/http";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: "",
    info: {
      nickName: ""
    },
    roles: [],
    menu: []
  },
  getters: {

  },
  mutations: {
    SET_TOKEN(state,data) {
      state.token = data;
    },
    SET_ROLES(state,data) {
      state.roles = data;
    },
    SET_MENU(state,data) {
      state.menu = data;
    },
    SET_INFO(state,data) {
      state.info = data;
    }
  },
  actions: {
    GenerateRoutes({ state,rootState,rootGetters,getters,commit,dispatch }) {
      return new Promise((resolve, reject) => {
        post("admin/get_menu")
            .then(async (res) => {
              let data = res.data;
              commit("SET_MENU",data);
              let routerAll = await getMenu(state.menu);
              resolve(routerAll);
            })
            .catch(err => {})
      })
    }
  },
  modules: {

  },
  plugins: [createPersistedState({
    key: "mei-ling-manage",
    storage: window.sessionStorage
  })],
})